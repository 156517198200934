import React, { useEffect, useState, useMemo } from "react";
import { Form, Formik } from "formik";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ANIMATION, ANIMATION_DURATION, FORM_HOST, FORM_STATUS } from "../../Variables";
import SimpleInput from "../SimpleInput";
import * as Yup from "yup";

const ContactSection = ({ data, texts = {} }) => {
	const [userFormData, setUserFormData] = useState({});
	const [formStatus, setFormStatus] = useState(FORM_STATUS.unset);

	const formSchema = useMemo(
		() =>
			Yup.object().shape({
				email: Yup.string().email(texts.error).required(texts.required),
				message: Yup.string().min(10, texts.tooShort).required(texts.required)
			}),
		[texts]
	);

	const formData = useMemo(
		() => [
			{
				name: "email",
				label: texts.email,
				type: "email",
				placeholder: ""
			},
			{
				name: "message",
				label: texts.message,
				type: "textarea",
				placeholder: ""
			}
		],
		[texts]
	);

	useEffect(() => {
		if (userFormData !== {}) {
			const body = new FormData();

			body.append("your-email", userFormData.email);
			body.append("your-message", userFormData.message);

			fetch(FORM_HOST, {
				method: "POST",
				body
			})
				.then((response) => response.json())
				.then((response) => {
					switch (response.status) {
						case "mail_sent":
							// success
							setFormStatus(FORM_STATUS.success);
							break;
						default:
							setFormStatus(FORM_STATUS.error);
							break;
					}
				})
				.catch((error) => {
					// Handle the case when there's a problem with the request
					console.error(error);
				});
		}
	}, [userFormData]);

	return (
		<section id="contact" className="section-light">
			<h1>{data.contact_title ? data.contact_title : ""}</h1>

			{data.contacts_repeater && (
				<ul className="contacts-link-container">
					{data.contacts_repeater.map((c, i) => (
						<>
							<li key={`contact-${i}`} className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
								{c.contacts_link && (
									<a href={c.contacts_link}>
										{c.contacts_image && (
											<img src={c.contacts_image.url} alt={c.contacts_image.alt} />
										)}
										<p>{c.contacts_content}</p>
									</a>
								)}
							</li>
						</>
					))}
				</ul>
			)}

			<SwitchTransition>
				<CSSTransition
					key={formStatus !== FORM_STATUS.success ? " form-visible" : "form-hidden"}
					timeout={{
						appear: 0,
						enter: ANIMATION_DURATION,
						exit: ANIMATION_DURATION
					}}
					classNames={ANIMATION.cartes}
				>
					{formStatus !== FORM_STATUS.success ? (
						<Formik
							initialValues={{
								email: "",
								message: ""
							}}
							validationSchema={formSchema}
							onSubmit={(values) => {
								setUserFormData(values);
							}}
						>
							{
								(/*{ errors }*/) => (
									<Form>
										{formData.map((s) => (
											<SimpleInput key={s.name} {...s} />
										))}

										<button type="submit">{texts.submitBtn}</button>
									</Form>
								)
							}
						</Formik>
					) : (
						<h2>{texts.successMessage}</h2>
					)}
				</CSSTransition>
			</SwitchTransition>
		</section>
	);
};

export default ContactSection;
