import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NoPage from "./views/NoPage";
import Home from "./views/Home";
import { PATHS } from "./Variables";

function App() {
    return (
        <Router>
            <Switch>
                <Route path={PATHS.home} exact component={Home} />
                <Route component={NoPage} />
            </Switch>
        </Router>
    );
}

export default App;
