import { useMemo } from "react";
import { PATHS, ANIMATION } from "../Variables";

import { SET_NAVBAR_STATE } from "../reducers/NavbarReducer";
import { useAppState } from "../context/mainContext";
import { LANGUAGES, SET_LANGUAGE } from "../reducers/SettingsReducer";
import { NAVBAR } from "../Textes";
import { isMobileOnly } from "react-device-detect";
import Animate from "../components/Animate";

function Navbar() {
	const {
		state: { isNavBarOpen, language },
		dispatch
	} = useAppState();

	const pageContent = useMemo(() => {
		return {
			home: NAVBAR.home[language],
			about: NAVBAR.about[language],
			singles: NAVBAR.singles[language],
			contact: NAVBAR.contact[language]
		};
	}, [language]);

	return (
		<nav>
			<ul className="language-container burger-visible">
				{Object.keys(LANGUAGES).map((key) => (
					<li key={key}>
						<button type="button" onClick={() => dispatch({ type: SET_LANGUAGE, payload: LANGUAGES[key] })}>
							{LANGUAGES[key]}
						</button>
					</li>
				))}
			</ul>
			<button
				className={`burger-menu${isNavBarOpen ? " is-active" : ""}`}
				onClick={() => dispatch({ type: SET_NAVBAR_STATE, payload: !isNavBarOpen })}
			>
				<span></span>
				<span></span>
				<span></span>
			</button>
			<div className={isNavBarOpen ? "is-open" : ""}>
				<ul className="links-container">
					<li>
						<a
							onClick={
								isMobileOnly && isNavBarOpen
									? () => dispatch({ type: SET_NAVBAR_STATE, payload: false })
									: null
							}
						>
							<Animate animKey={pageContent.home} type={ANIMATION.text}>
								<span>{pageContent.home}</span>
							</Animate>
						</a>
					</li>
					<li>
						<a
							href={PATHS.homeSingle}
							onClick={
								isMobileOnly && isNavBarOpen
									? () => dispatch({ type: SET_NAVBAR_STATE, payload: false })
									: null
							}
						>
							<Animate animKey={pageContent.singles} type={ANIMATION.text}>
								<span>{pageContent.singles}</span>
							</Animate>
						</a>
					</li>
					<li>
						<a
							href={PATHS.homeAlbum}
							onClick={
								isMobileOnly && isNavBarOpen
									? () => dispatch({ type: SET_NAVBAR_STATE, payload: false })
									: null
							}
						>
							<Animate animKey={pageContent.home} type={ANIMATION.text}>
								<span>Album : Récit d'un éveil</span>
							</Animate>
						</a>
					</li>
					<li>
						<a
							href={PATHS.homeAbout}
							onClick={
								isMobileOnly && isNavBarOpen
									? () => dispatch({ type: SET_NAVBAR_STATE, payload: false })
									: null
							}
						>
							<Animate animKey={pageContent.about} type={ANIMATION.text}>
								<span>{pageContent.about}</span>
							</Animate>
						</a>
					</li>
					<li>
						<a
							href={PATHS.homeContact}
							onClick={
								isMobileOnly && isNavBarOpen
									? () => dispatch({ type: SET_NAVBAR_STATE, payload: false })
									: null
							}
						>
							<Animate animKey={pageContent.contact} type={ANIMATION.text}>
								<span>{pageContent.contact}</span>
							</Animate>
						</a>
					</li>

					<li>
						<ul className="language-container burger-hidden">
							{Object.keys(LANGUAGES).map((key) => (
								<li key={key}>
									<button
										type="button"
										onClick={() => dispatch({ type: SET_LANGUAGE, payload: LANGUAGES[key] })}
									>
										{LANGUAGES[key]}
									</button>
								</li>
							))}
						</ul>
					</li>
				</ul>
			</div>
		</nav>
	);
}

export default Navbar;
