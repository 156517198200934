import { useState, useEffect, useMemo } from "react";
import { AUDIO_STATE } from "../Variables";
import { SET_AUDIO_STATE } from "../reducers/SettingsReducer";
import { useAppState } from "../context/mainContext";
import { isMobileOnly } from "react-device-detect";
import { Howl } from "howler";
import Lottie from "lottie-react";
import soundAnimation from "../assets/lottie/sound-indicator.json";
import { ANIMATION, ANIMATION_DURATION } from "../Variables";
import { TEXTES_CARTES } from "../Textes";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const Single = (props) => {
	const SCREEN_HEIGHT = window?.innerHeight;
	const STEP_PX = SCREEN_HEIGHT * 0.25;
	const SCALE_MAX_POS = SCREEN_HEIGHT * 0.4 + STEP_PX / 2;
	const SCALE_MAX_POS_SND = SCALE_MAX_POS - STEP_PX;
	const SCALE_MIN = 0.9;
	const SCALE_MAX = 1.0;
	const VERTICALE_MARGIN = 50;
	const DELTA_SCALE = SCALE_MAX - SCALE_MIN;
	const DELTA_PX = SCREEN_HEIGHT - VERTICALE_MARGIN - SCALE_MAX_POS;
	const DELTA_SCALE_BY_PX = DELTA_SCALE / DELTA_PX;

	//let isFadeOut = false;
	const [volume, setVolume] = useState(0.0);
	const [currentStyle, setCurrentStyle] = useState({});
	const [currentScale, setCurrentScale] = useState(SCALE_MIN);
	const [localAudioState, setLocalAudioState] = useState(AUDIO_STATE.unset);
	const { data, id, firstId, lastId } = props;
	const [currentSound] = useState(data.single_teaser.url);

	const sound = useMemo(
		() =>
			new Howl({
				xhr: {
					method: "GET",
					headers: {},
					mode: "no-cors",
					withCredentials: true
				},
				html5: true,
				preload: true,
				src: [currentSound],
				loop: false,
				volume: 0.0,
				autoUnlock: true,
				onplayerror: () => {
					sound.once("unlock", function () {
						sound.play();
					});
				}
			}),
		[currentSound]
	);

	const {
		state: { audioState, language },
		dispatch
	} = useAppState();

	const pageContent = useMemo(() => {
		return {
			waiting: TEXTES_CARTES.waiting[language]
		};
	}, [language]);

	useEffect(() => {
		if (isMobileOnly) {
			const card = document.querySelector(`#${id}`);

			const handleScroll = () => {
				const yPos = card ? card.getBoundingClientRect().top : null;

				if (yPos) {
					if (yPos >= SCALE_MAX_POS) {
						setLocalAudioState(AUDIO_STATE.stop);

						if (id === firstId) {
							dispatch({ type: SET_AUDIO_STATE, payload: AUDIO_STATE.stop });
						}

						const nbrPx = Math.round(DELTA_PX - (yPos - SCALE_MAX_POS));
						const deltaScale = Math.round(nbrPx * DELTA_SCALE_BY_PX * 1000) / 1000;

						let scale = SCALE_MIN + deltaScale;

						if (scale > SCALE_MAX) {
							scale = SCALE_MAX;
						}

						if (nbrPx < 0) {
							scale = SCALE_MIN;
						}

						setCurrentScale(scale);
					} else if (yPos > VERTICALE_MARGIN && yPos <= SCALE_MAX_POS && yPos > SCALE_MAX_POS - STEP_PX) {
						// dans les 50 px au scale 1, le son se joue
						setLocalAudioState(AUDIO_STATE.play);

						if (audioState !== AUDIO_STATE.play) {
							dispatch({ type: SET_AUDIO_STATE, payload: AUDIO_STATE.play });
						}
					} else if (yPos >= VERTICALE_MARGIN) {
						// la carte rétréci à nouveau

						setLocalAudioState(AUDIO_STATE.stop);

						if (id === lastId) {
							dispatch({ type: SET_AUDIO_STATE, payload: AUDIO_STATE.stop });
						}

						const nbrPx = Math.round(SCALE_MAX_POS_SND - yPos);

						const deltaScale = Math.round(nbrPx * DELTA_SCALE_BY_PX * 1000) / 1000;

						let scale = SCALE_MAX - deltaScale;

						if (scale < SCALE_MIN) {
							scale = SCALE_MIN;
						}

						if (nbrPx < 0) {
							scale = SCALE_MAX;
						}

						setCurrentScale(scale);
					}
				}
			};

			window.addEventListener("scroll", handleScroll);

			return () => window.removeEventListener("scroll", handleScroll);
		}
	}, []);

	useEffect(() => {
		if (currentScale) {
			setCurrentStyle({
				transform: `scale(${currentScale})`
			});
		}
	}, [currentScale]);

	useEffect(() => {
		if (volume >= 0 && volume <= 1) {
			sound.volume(volume);
		}
	}, [volume]);

	useEffect(() => {
		let interval = null;
		let timer = null;
		let vol = volume;
		switch (localAudioState) {
			case AUDIO_STATE.play:
				if (sound) {
					if (isMobileOnly) {
						timer = setTimeout(() => {
							!sound.playing() && sound.play();
							setVolume(1);
						}, 125);
					} else {
						!sound.playing() && sound.play();
						interval = setInterval(() => {
							if (vol + 0.01 < 1) {
								vol += 0.01;
								setVolume(vol);
							} else {
								setVolume(1);
								clearInterval(interval);
							}
						}, 15);
					}
				}

				break;
			case AUDIO_STATE.stop:
				if (isMobileOnly) {
					clearTimeout(timer);
					setVolume(0);
					sound && sound.stop();
				} else {
					interval = setInterval(() => {
						if (vol - 0.01 > 0) {
							vol -= 0.01;
							setVolume(vol);
						} else {
							setVolume(0);
							clearInterval(interval);
							sound && sound.stop();
						}
					}, 15);
				}

				break;
			default:
				break;
		}

		return () => {
			interval && clearInterval(interval);
			timer && clearTimeout(timer);
		};
	}, [localAudioState]);

	useEffect(() => {
		if (!isMobileOnly) {
			dispatch({ type: SET_AUDIO_STATE, payload: localAudioState });
		}
	}, [localAudioState]);

	return (
		<li id={id} style={isMobileOnly ? currentStyle : {}} className="single-card col-xs-12">
			<figure>
				<img src={data.single_image.url} alt={data.single_image.alt} />
			</figure>
			<div className={`single-details-container`}>
				<h2>{data.single_title}</h2>
				<div className={`padding-container${localAudioState === AUDIO_STATE.play ? " is-active" : ""}`}>
					<div
						className={`hoverable-container`}
						onMouseEnter={!isMobileOnly ? () => setLocalAudioState(AUDIO_STATE.play) : null}
						onMouseLeave={!isMobileOnly ? () => setLocalAudioState(AUDIO_STATE.stop) : null}
					>
						<div className="single-text-container">
							<SwitchTransition>
								<CSSTransition
									key={localAudioState}
									timeout={{
										appear: 0,
										enter: ANIMATION_DURATION,
										exit: ANIMATION_DURATION
									}}
									classNames={ANIMATION.cartes}
								>
									{localAudioState === AUDIO_STATE.play ? (
										<p>{data.single_description}</p>
									) : (
										<p>{pageContent.waiting}</p>
									)}
								</CSSTransition>
							</SwitchTransition>
						</div>
						<Lottie className="sound-lottie" animationData={soundAnimation} />
					</div>
				</div>
			</div>
		</li>
	);
};

export default Single;
