import { useEffect, useState, useMemo } from "react";
import useAPI from "../hooks/useAPI";
import { API_ALBUMS, API_PAGES, AUDIO_STATE, API_SINGLES } from "../Variables";
import axios from "axios";
import MetaTags from "react-meta-tags";

import { useAppState } from "../context/mainContext";
import { SET_SITE_DATA } from "../reducers/MainReducer";
import { SET_ALBUMS_DATA, SET_SINGLES_DATA } from "../reducers/AlbumsReducer";
import { NAVBAR, TEXTES_ACCUEIL } from "../Textes";
import HomeSection from "../components/sections/HomeSection";
import AlbumSection from "../components/sections/AlbumSection";
import AboutSection from "../components/sections/AboutSection";
import ContactSection from "../components/sections/ContactSection";
import SinglesSection from "../components/sections/SinglesSection";

/**
 * permet d'appliquer les <br /> d'un string
 * @param {String} str string auquel appliqué les lines separator
 * @returns
 */
export function applyLineSeparator(str) {
	if (str) {
		return str.split("<br />").map((line, i) => (
			<span key={i}>
				{line}
				<br />
			</span>
		));
	}

	return "";
}

const getAlbums = async (dispatch, link, method = "GET", data = null) => {
	let result = { data: [], error: false };

	let body = {
		method: method,
		url: link,
		data: data
	};
	axios(body)
		.then((res) => {
			result = { data: res.data, error: false };
			dispatch({ type: SET_ALBUMS_DATA, payload: result });
		})
		.catch(() => {
			result = { data: [], error: true };
			dispatch({ type: SET_ALBUMS_DATA, payload: result });
		});
};

const getSingles = async (dispatch, link, method = "GET", data = null) => {
	let result = { data: [], error: false };

	let body = {
		method: method,
		url: link,
		data: data
	};
	axios(body)
		.then((res) => {
			result = { data: res.data, error: false };
			dispatch({ type: SET_SINGLES_DATA, payload: result });
		})
		.catch(() => {
			result = { data: [], error: true };
			dispatch({ type: SET_SINGLES_DATA, payload: result });
		});
};

function Home() {
	const { data /*error*/ } = useAPI(API_PAGES, "GET");
	const [pageData, setPageData] = useState([]);
	const [groupHome, setGroupHome] = useState([]);
	const [groupAbout, setGroupAbout] = useState([]);
	const [groupContact, setGroupContact] = useState([]);
	const [highlightedAlbum, setHighlightedAlbum] = useState(null);
	const [highlightedSingles, setHighlightedSingles] = useState(null);

	const {
		state: { siteData, language, singlesData, albumsData, audioState },
		dispatch
	} = useAppState();

	const pageContent = useMemo(() => {
		return {
			singles: NAVBAR.singles[language],
			streamings: {
				listenNow: TEXTES_ACCUEIL.streamings.listenNow[language],
				linkToAllPlateforms: TEXTES_ACCUEIL.streamings.linkToAllPlateforms[language]
			},
			form: {
				email: TEXTES_ACCUEIL.form.email[language],
				message: TEXTES_ACCUEIL.form.message[language],
				submitBtn: TEXTES_ACCUEIL.form.submitBtn[language],
				required: TEXTES_ACCUEIL.form.required[language],
				tooShort: TEXTES_ACCUEIL.form.tooShort[language],
				error: TEXTES_ACCUEIL.form.error[language],
				successMessage: TEXTES_ACCUEIL.form.successMessage[language]
			}
		};
	}, [language]);

	const PAGE_NAME = "home";

	useEffect(() => {
		if (albumsData) {
			const _highlightedAlbum = albumsData?.find((a) => a.album_is_highlighted === true);

			if (_highlightedAlbum) {
				setHighlightedAlbum(_highlightedAlbum);
			}
		}
	}, [albumsData]);

	useEffect(() => {
		if (singlesData) {
			const _highlightedSingle = singlesData?.filter((a) => a.single_is_highlighted === true);

			if (_highlightedSingle) {
				setHighlightedSingles(_highlightedSingle);
			}
		}
	}, [singlesData]);

	useEffect(() => {
		let linkAlbums = API_ALBUMS;
		let linkSingles = API_SINGLES;

		if (language !== "fr") {
			linkAlbums += `_${language}`;
			linkSingles += `_${language}`;
		}

		getAlbums(dispatch, linkAlbums);
		getSingles(dispatch, linkSingles);
	}, [language]);

	useEffect(() => {
		if (siteData) {
			for (let i = 0; i < siteData.length; i++) {
				if (siteData[i].acf.group_metadata.page_language === language) {
					if (siteData[i].acf.group_metadata.page_title === PAGE_NAME) {
						setPageData(siteData[i].acf);
						break;
					}
				}
			}

			// faire code si boucle se termine sans avoir trouvé le contenu
		}
	}, [siteData, language]);

	useEffect(() => {
		dispatch({ type: SET_SITE_DATA, payload: data });
	}, [data]);

	useEffect(() => {
		pageData.group_home && setGroupHome(pageData.group_home);
		pageData.group_about && setGroupAbout(pageData.group_about);
		pageData.group_contact && setGroupContact(pageData.group_contact);
	}, [pageData]);

	return (
		<main className="home">
			<MetaTags>
				<title>Sacha Schlumpf - Piano</title>
				<meta name="description" content="Le piano qui raconte une histoire" />
				<meta property="og:title" content="Sacha Schlumpf - Piano" />
				<meta property="og:description" content="Le piano qui raconte une histoire" />
				<meta property="og:url" content="https://sacha-schlumpf.ch" />
				<meta property="og:site_name" content="Sacha Schlumpf - Piano" />
				<meta name="twitter:image:alt" content="Image du site de Sacha Schlumpf" />
			</MetaTags>

			<span className={`dark-bg${audioState === AUDIO_STATE.play ? " is-visible" : ""}`}></span>

			{groupHome && <HomeSection data={groupHome} texts={pageContent?.streamings} />}

			{highlightedSingles?.length > 0 && <SinglesSection data={highlightedSingles} title={pageContent.singles} />}

			{highlightedAlbum && <AlbumSection data={highlightedAlbum} texts={pageContent?.streamings} />}

			{groupAbout && <AboutSection data={groupAbout} />}

			{groupContact && <ContactSection data={groupContact} texts={pageContent.form} />}
		</main>
	);
}

export default Home;
