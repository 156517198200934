export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_AUDIO_STATE = "SET_AUDIO_STATE";

export const LANGUAGES = {
	french: "fr",
	english: "en",
	german: "de"
};

const settingsReducer = (state, action = {}) => {
	switch (action.type) {
		case SET_LANGUAGE: {
			return { ...state.settings, language: action.payload };
		}
		case SET_AUDIO_STATE: {
			return { ...state.settings, audioState: action.payload };
		}
		default: {
			return state;
		}
	}
};

export default settingsReducer;
