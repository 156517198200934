/** */
export const HOST =
	process.env.NODE_ENV === "development" ? "https://piano.localhost:8890" : "https://admin.sacha-schlumpf.ch"; // eslint-disable-line
//export const HOST = "https://192.168.1.116:8890";
export const API_HOST = HOST + "/wp-json/wp/v2";
export const API_HOST_ACF = HOST + "/wp-json/acf/v3";

// API PATHS
// get posts
export const API_POSTS = API_HOST_ACF + "/posts";
// get albums
export const API_ALBUMS = API_HOST_ACF + "/albums";
// get singles
export const API_SINGLES = API_HOST_ACF + "/singles";

// get pages
export const API_PAGES = API_HOST_ACF + "/pages";

export const FORM_HOST = HOST + "/wp-json/contact-form-7/v1/contact-forms/91/feedback";

// chemin sur l'app
export const PATHS = {
	home: "/",
	homeAlbum: "#album",
	homeSingle: "#morceaux",
	homeAbout: "#a-propos",
	homeContact: "#contact"
};

// état du lecteur audio
export const AUDIO_STATE = {
	stop: "stop",
	play: "play",
	pause: "pause",
	unset: "unset"
};

export const ANIMATION = {
	text: "text",
	logo: "logo",
	fade: "fade",
	cartes: "cartes"
};

export const FORM_STATUS = {
	error: "error",
	success: "success",
	unset: "unset"
};

export const ANIMATION_DURATION = 250;
