import { ErrorMessage, Field } from "formik";

function SimpleInput(props) {
    return (
        <>
            <label htmlFor={props.name}>{props.label}</label>
            {props.type !== "textarea" ? (
                <Field id={props.name} name={props.name} type={props.type} placeholder={props.placeholder} />
            ) : (
                <Field id={props.name} name={props.name} rows="8" component="textarea" placeholder={props.placeholder} />
            )}
            <ErrorMessage component="span" name={props.name} />
        </>
    );
}

export default SimpleInput;
