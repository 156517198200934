export const SET_SITE_DATA = "SET_SITE_DATA";

const mainReducer = (state, action = {}) => {
    switch (action.type) {
        case SET_SITE_DATA: {
            window.localStorage.setItem("siteData", JSON.stringify(action.payload));
            return { ...state.main, siteData: action.payload ? action.payload : [] };
        }
        default: {
            return state;
        }
    }
};

export default mainReducer;
