import { AUDIO_STATE } from "../Variables";
import { LANGUAGES } from "./SettingsReducer";

const initialState = {
	siteData:
		window.localStorage.getItem("siteData") !== "undefined"
			? JSON.parse(window.localStorage.getItem("siteData"))
			: [],
	albumsData:
		window.localStorage.getItem("albumsData") !== "undefined"
			? JSON.parse(window.localStorage.getItem("albumsData"))
			: [],
	singlesData:
		window.localStorage.getItem("singlesData") !== "undefined"
			? JSON.parse(window.localStorage.getItem("singlesData"))
			: [],
	language: LANGUAGES.french,
	audioState: AUDIO_STATE.stop
};

const combineReducers = (reducers) => {
	return (state, action) => {
		return Object.keys(reducers).reduce((acc, prop) => {
			return {
				...acc,
				...reducers[prop]({ [prop]: acc[prop] }, action)
			};
		}, state);
	};
};

export { initialState, combineReducers };
