import React from "react";
import { applyLineSeparator } from "../../views/Home";

const AboutSection = ({ data }) => {
	return (
		<section id="a-propos" className="section-light">
			<div className="background-container no-filter">
				{data.about_background_image && (
					<img src={data.about_background_image.url} alt={data.about_background_image.alt} />
				)}
			</div>
			<h1>{data.about_title ? data.about_title : ""}</h1>
			<p className="about-content col-xs-12 col-md-8 col-lg-6 col-xl-4">
				{data.about_content ? applyLineSeparator(data.about_content) : ""}
			</p>
		</section>
	);
};

export default AboutSection;
