import React from "react";
import { isMobileOnly } from "react-device-detect";
import RIGHT_BLACK_ARROW from "../../assets/img/fleche_noir.svg";
import { applyLineSeparator } from "../../views/Home";
import Song from "../Song";

const AlbumSection = ({ data, texts = {} }) => {
	return (
		<section id="album" className="section-light">
			<p className="tagline">{data.album_tagline ? data.album_tagline : ""}</p>
			<h1>{data.album_title}</h1>
			<div className="album-container">
				<p className="col-xs-12 col-md-8 col-lg-6">
					{data.album_description ? applyLineSeparator(data.album_description) : ""}
				</p>
				<figure className="col-xs-12 col-md-4">
					{data.album_cover_image && (
						<img src={data.album_cover_image.url} alt={data.album_cover_image.alt} />
					)}
				</figure>
			</div>
			<ul className={`songs-container${isMobileOnly ? " is-mobile" : ""}`}>
				{data.album_song_repeater &&
					data.album_song_repeater.map((s, i) => (
						<Song
							key={`song-${i}`}
							data={s}
							id={`song-${i}`}
							firstId="song-0"
							lastId={`song-${data.album_song_repeater.length - 1}`}
							isSingle={false}
						/>
					))}
			</ul>

			<div className="streaming-container">
				<h3>{texts.listenNow}</h3>

				{data.album_links_to_streaming && (
					<ul>
						{data.album_links_to_streaming.map((l, i) => (
							<li key={`plateforme-${i}`}>
								<a href={l.links_to_plateform}>
									<img src={l.links_image.url} alt={l.links_image.alt} />
								</a>
							</li>
						))}
					</ul>
				)}

				{data.album_link_to_all_plateformes && (
					<a className="link-to-all-plateforms" href={data.album_link_to_all_plateformes}>
						<span>{texts.linkToAllPlateforms}</span>
						<img src={RIGHT_BLACK_ARROW} alt="" />
					</a>
				)}
			</div>
		</section>
	);
};

export default AlbumSection;
