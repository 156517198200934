import React, { createContext, useReducer, useContext } from "react";

const MainContext = createContext();

export function MainProvider({ reducer, initialState = {}, children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

export function useAppState() {
    const context = useContext(MainContext);
    if (context === undefined) {
        throw new Error("unable to find MainContext");
    }

    return context;
}
