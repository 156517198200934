import { useState, useEffect } from "react";
import axios from "axios";

const useAPI = (link, method = null, data = null) => {
    const [result, setResult] = useState({ data: [], error: true });

    const fetchAPI = async (l) => {
        let body = {
            method: method,
            url: l,
            data: data
        };
        axios(body)
            .then((res) => {
                setResult({ data: res.data, error: false });
            })
            .catch((/*err*/) => {
                setResult({ data: [], error: true /*err.response.statusText*/ });
            });
    };

    useEffect(() => {
        if (method) {
            fetchAPI(link);
        }
    }, []);

    useEffect(() => {
        if (method) {
            fetchAPI(link);
        }
    }, [data, link, method]);

    return result;
};

export default useAPI;
