import React, { useState, useEffect } from "react";
import BackgroundGroupHome from "../../assets/img/accueil.jpg";
import { useAppState } from "../../context/mainContext";
import Animate from "../Animate";
import Navbar from "../Navbar";
import { isMobileOnly } from "react-device-detect";
import { SET_NAVBAR_STATE } from "../../reducers/NavbarReducer";
import { ANIMATION } from "../../Variables";
import RIGHT_WHITE_ARROW from "../../assets/img/fleche_blanc.svg";

const HomeSection = ({ data, texts = {} }) => {
	const {
		state: { isNavBarOpen },
		dispatch
	} = useAppState();

	const [canShowTitle, setCanShowTitle] = useState(false);
	const [canShowSubtitle, setCanShowSubtitle] = useState(false);
	const [canShowStreamingLogos, setCanShowStreamingLogos] = useState(false);

	useEffect(() => {
		if (data && !canShowStreamingLogos) {
			setTimeout(() => {
				setCanShowTitle(true);
			}, 500);
			setTimeout(() => {
				setCanShowSubtitle(true);
			}, 950);
			setTimeout(() => {
				setCanShowStreamingLogos(true);
			}, 1450);
		}
	}, [data]);

	return (
		<section id="accueil" className={isNavBarOpen ? "navbar-is-open" : ""}>
			<Navbar />

			<div className="background-container">
				<img src={BackgroundGroupHome} alt="" />
			</div>
			<div
				className="title-container"
				onTouchStart={
					isMobileOnly && isNavBarOpen ? () => dispatch({ type: SET_NAVBAR_STATE, payload: false }) : null
				}
			>
				<h1 className={canShowTitle ? " is-visible" : ""}>
					<Animate animKey={data.left_title} type={ANIMATION.text}>
						<span>{data.left_title}</span>
					</Animate>
					<br />
					<Animate animKey={data.left_title_2} type={ANIMATION.text}>
						<span>{data.left_title_2}</span>
					</Animate>
				</h1>
				<h2 className={canShowSubtitle ? " is-visible" : ""}>
					<Animate animKey={data.right_title} type={ANIMATION.text}>
						<span>{data.right_title}</span>
					</Animate>
					<br />
					<Animate animKey={data.right_title_2} type={ANIMATION.text}>
						<span>{data.right_title_2}</span>
					</Animate>
				</h2>
			</div>
			<div
				className={`streaming-container${canShowStreamingLogos ? " is-visible" : ""}`}
				onTouchStart={
					isMobileOnly && isNavBarOpen ? () => dispatch({ type: SET_NAVBAR_STATE, payload: false }) : null
				}
			>
				{data.home_links_to_streaming && (
					<ul>
						{data.home_links_to_streaming.map((l, i) => (
							<li className="col-xs-12 col-md-6 col-lg-4" key={`plateforme-${i}`}>
								<Animate animKey={l.link_image.url ? l.link_image.url : "none"} type={ANIMATION.logo}>
									<a href={l.link_to_plateform}>
										<img src={l.link_image.url} alt={l.link_image.alt} />
									</a>
								</Animate>
							</li>
						))}
					</ul>
				)}
				{data.home_link_to_all_plateforms && (
					<Animate animKey={texts.linkToAllPlateforms} type={ANIMATION.text}>
						<a className="link-to-all-plateforms" href={data.home_link_to_all_plateforms}>
							<span>{texts.linkToAllPlateforms}</span>
							<img src={RIGHT_WHITE_ARROW} alt="" />
						</a>
					</Animate>
				)}
			</div>
		</section>
	);
};

export default HomeSection;
