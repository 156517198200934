export const SET_ALBUMS_DATA = "SET_ALBUMS_DATA";
export const SET_SINGLES_DATA = "SET_SINGLES_DATA";

const albumsReducer = (state, action = {}) => {
	switch (action.type) {
		case SET_ALBUMS_DATA: {
			let albumsData = [];

			if (action.payload) {
				if (action.payload.data) {
					for (let i = 0; i < action.payload.data.length; i++) {
						if (action.payload.data[i].acf.albums_repeater) {
							albumsData = action.payload.data[i].acf.albums_repeater;
						}
					}
				}

				window.localStorage.setItem("albumsData", JSON.stringify(albumsData));
				return { ...state.albums, albumsData: albumsData ? albumsData : [] };
			}

			return state;
		}
		case SET_SINGLES_DATA: {
			let singlesData = [];

			if (action.payload) {
				if (action.payload.data) {
					for (let i = 0; i < action.payload.data.length; i++) {
						if (action.payload.data[i].acf.singles_repeater) {
							singlesData = action.payload.data[i].acf.singles_repeater;
						}
					}
				}

				window.localStorage.setItem("singlesData", JSON.stringify(singlesData));
				return { ...state.albums, singlesData: singlesData ? singlesData : [] };
			}

			return state;
		}
		default: {
			return state;
		}
	}
};

export default albumsReducer;
