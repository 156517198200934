import React from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import Single from "../Single";
import Song from "../Song";

const SinglesSection = ({ data, title = "" }) => {
	return (
		<section id="singles" className="section-light">
			<h1>{title}</h1>

			<ul className={`singles-container${isMobileOnly ? " is-mobile" : ""}`}>
				{data?.map((s, i) => {
					if (isMobile) {
						return (
							<Song
								key={`single-mobile-${i}`}
								data={{
									song_image: s.single_image,
									song_title: s.single_title,
									song_description: s.single_description,
									song_teaser: s.single_teaser
								}}
								id={`single-mobile-${i}`}
								firstId="single-mobile-0"
								lastId={`single-mobile-${data.length - 1}`}
								isSingle={true}
							/>
						);
					} else {
						return (
							<Single
								key={`single-${i}`}
								data={s}
								id={`single-${i}`}
								firstId="single-0"
								lastId={`single-${data.length - 1}`}
							/>
						);
					}
				})}
			</ul>
		</section>
	);
};

export default SinglesSection;
