export const NAVBAR = {
	home: {
		fr: "Accueil",
		en: "Home",
		de: "Startseite"
	},
	singles: {
		fr: "Morceaux",
		en: "Singles",
		de: "Titel"
	},
	about: {
		fr: "À propos",
		en: "About",
		de: "Über ihn"
	},
	contact: {
		fr: "Contact",
		en: "Contact",
		de: "Kontakt"
	}
};
export const TEXTES_ACCUEIL = {
	streamings: {
		listenNow: {
			fr: "Écouter maintenant",
			en: "Listen now",
			de: "Jetzt anhören"
		},
		linkToAllPlateforms: {
			fr: "Plus de plateformes",
			en: "More plateforms",
			de: "Weitere Plattformen"
		}
	},
	form: {
		email: {
			fr: "Adresse email",
			en: "E-mail address",
			de: "E-Mail-Address"
		},
		message: {
			fr: "Votre message",
			en: "Your message",
			de: "Ihre Nachricht"
		},
		submitBtn: {
			fr: "Envoyer",
			en: "Send",
			de: "Senden"
		},
		required: {
			fr: "Ce champ est obligatoire",
			en: "Required field",
			de: "Dieses Feld ist obligatorisch "
		},
		tooShort: {
			fr: "Trop court",
			en: "Too short",
			de: "Zu kurz"
		},
		error: {
			fr: "Votre saisie semble incorrecte",
			en: "Something looks wrong",
			de: "Ihre Eingabe scheint nicht korrekt zu sein"
		},
		successMessage: {
			fr: "Votre formulaire a bien été envoyé. Merci !",
			en: "Your form has been sent successfully. Thank you !",
			de: "Ihr Formular wurde weitergeleitet. Vielen Dank!"
		}
	}
};

export const TEXTES_CARTES = {
	waiting: {
		fr: "Écouter un extrait",
		en: "Listen to the extract",
		de: "Ausschnitt anhören"
	}
};
