import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/styles.css";

import { MainProvider } from "./context/mainContext";
import { initialState, combineReducers } from "./reducers/index";

import mainReducer from "./reducers/MainReducer";
import navbarReducer from "./reducers/NavbarReducer";
import settingsReducer from "./reducers/SettingsReducer";
import albumsReducer from "./reducers/AlbumsReducer";

const appReducers = combineReducers({
    main: mainReducer,
    navbar: navbarReducer,
    settings: settingsReducer,
    albums: albumsReducer
});

ReactDOM.render(
    <React.StrictMode>
        <MainProvider reducer={appReducers} initialState={initialState}>
            <App />
        </MainProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
