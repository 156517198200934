export const SET_NAVBAR_STATE = "SET_NAVBAR_STATE";

const navbarReducer = (state, action = {}) => {
    switch (action.type) {
        case SET_NAVBAR_STATE: {
            return { ...state.navbar, isNavBarOpen: action.payload };
        }
        default: {
            return state;
        }
    }
};

export default navbarReducer;
