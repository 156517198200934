import { Link } from "react-router-dom";

function NoPage() {
    return (
        <main className="404 section-light">
            <h1>Erreur 404</h1>
            <h2>Ooups ! Il semblerait que vous vous soyez perdu</h2>

            <Link to="/">Aller sur la page d'accueil</Link>
        </main>
    );
}

export default NoPage;
