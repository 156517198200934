import { useState, useEffect } from "react";
import { useAppState } from "../context/mainContext";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ANIMATION_DURATION } from "../Variables";

function Animate(props) {
    const {
        state: { language }
    } = useAppState();

    if (!props.children) {
        return null;
    }

    const [key, setKey] = useState(null);

    useEffect(() => {
        language && setKey(language);
    }, [language]);

    useEffect(() => {
        props.text && setKey(props.text);
    }, [props.text]);

    return (
        <SwitchTransition>
            <CSSTransition
                key={key}
                timeout={{
                    appear: 0,
                    enter: ANIMATION_DURATION,
                    exit: ANIMATION_DURATION
                }}
                classNames={props.type ? props.type : "fade"}
            >
                {props.children}
            </CSSTransition>
        </SwitchTransition>
    );
}

export default Animate;
